<template>
    <el-container>
      <div class="w-100">
        <el-card class="box-card pb-40" v-loading="loading">
          <div class="mod-title">
            <span>选择订阅行业纵览</span>
          </div>
          <div class="box-shadow mar-t-20"></div>
            <!-- 字体列表 -->
            <ul class="list">
              <li class="item" v-for="(row, index) in list" :key="index" v-show="row.state == 'enable'">
                <input type="checkbox" name="Storage" :id="labelFor(index + 1)" v-model="listMap[index].selected" @change="checkedChange(listMap[index])">
                <label :for="labelFor(index + 1)">
                  <span class="iconfont icon-xuanzhong"></span>
                  <span :class="mapIcon(index)"></span>
                  <p>{{ row.name }}</p>
                </label>
              </li>
            </ul>
            <!--@end 字体列表 -->
          <div class="button-group mar-t-45">
            <el-button type="primary" round class="selected" v-if="selected" @click="save">确定</el-button>
            <el-button type="primary" round v-else>确定</el-button>
            <el-button round v-if="cancelSelect" @click="cancel">取消</el-button>
          </div>
        </el-card>
      </div>
    </el-container>
</template>
<script>
    import { update, lists as repositoryList } from "@/api/repository";
    export default {
      name: "component-repository-select",
      props: ['alreadySelected'],
      data() {
        return {
          loading: false,
          list: [],
          listMap: [],
          selected: false,
          selectedIds: {},
          cancelSelect: false
        }
      },
      computed: {
        // 计算属性的 getter
      },
      mounted() {
        this.loadRepositorys()
      },
      methods: {
        cancel() {
          this.$emit('subscription', false)
        },
        save() {
          var ids = []
          for (let i in this.selectedIds) if (this.selectedIds[i]) ids.push(i)
          update({ids: ids.join(",")}).then(res => {
            if (res.data.state) {
              this.$message.success("订阅成功")
              this.$emit('subscription', false)
            } else {
              this.$message.error(res.data.error)
            }
          }).catch(res => {
            this.$message.error("订阅知识库失败，服务错误")
          })
        },
        checkedChange(row) {
          this.selectedIds[row.id] = row.selected
          var ids = []
          for (let i in this.selectedIds) if (this.selectedIds[i]) ids.push(i)
          this.selected = _.size(ids) >= 1 ? true : false
        },
        loadRepositorys() {
          var _this = this
          var alreadyIds = []
          this.loading = true
          _(this.alreadySelected).forEach((item, i) => {
            alreadyIds.push(item.id)
          })
          this.cancelSelect = alreadyIds.length >= 1 ? true : false
          repositoryList({}).then(res => {
            if (res.data.state) {
              this.list = res.data.data
              _(this.list).forEach((item, i) => {
                item.selected = alreadyIds.indexOf(item.id) === -1 ? false : true;
                if (item.selected) _this.selectedIds[item.id] = true;
                _this.listMap[i] = item
              })
              this.loading = false
            } else {
              this.$message.error(res.data.error)
            }
          }).catch(res => {
            this.$message.error("加载知识库列表失败，服务错误")
          })
        },
        mapIcon(index) {
          let icons = [
            'iconfont icon-keji jb', 'iconfont icon-youxi jb', 'iconfont icon-tiyu jb', 'iconfont icon-qiche jb', 'iconfont icon-yinhang jb',
            'iconfont icon-junshi jb', 'iconfont icon-lvyou jb', 'iconfont icon-jiaoyu jb', 'iconfont icon-zhengquan jb', 'iconfont icon-zhengwu jb',
            'iconfont icon-fangchan jb', 'iconfont icon-shiyao jb', 'iconfont icon-jiushui jb', 'iconfont icon-gonggonganquan jb', 'iconfont icon-wuliu jb',
            'iconfont icon-yule jb'
          ];
          return icons[index];
        },
        labelFor(index){
          return "mf" + index
        }
      }
    }
</script>
<style scoped>
.ico_list{text-align: center;width:1000px;margin:0 auto;}
/*模仿按钮*/
.mf{display: inline-block;margin:40px 10px 25px;cursor: pointer;}
.mf input{display: none;}
.mf label,.mf input:checked + label{background-position: 0 0;background-size: 100% 100%;background-repeat: no-repeat;}
.mf label{width:182px;height:150px;font-size:12px;margin:0 auto;display: inline-block;font-weight:400;}
.mf label img{margin-top:25px;}
.mf label p{line-height:40px;}
.button_group{margin-top: 60px;}
.column-5 li{margin:50px 5px 0;}
.pb_30{padding-bottom: 30px;}
/*字体列表*/
.list{text-align: center;width:1050px;margin: 0 auto;overflow: hidden;}
.list li{margin:50px 0 0 30px;float: left;padding-bottom: 10px;}
.item{display: inline-block;margin:40px 10px 25px;cursor: pointer;}
.item input{display: none;}
.item label{font-size:12px;margin:0 auto;display: inline-block;font-weight:400;float: left;box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);background: #fff;width: 175px;height: 150px;border-radius: 10px;text-align: center;position: relative;cursor: pointer;}
.item .icon-xuanzhong{display: none;}
.jb{font-size:50px;line-height:80px;margin-top: 20px;-webkit-background-clip:text; -webkit-text-fill-color:transparent;display: block;}
.item input:checked + label .icon-xuanzhong{position: absolute;bottom:0;right: 0;z-index: 10;font-size:20px;display: block;}
.list li p{font-size: 12px;line-height: 30px;}
.clear{clear: both;height: 0;}
.ckdysjk .list li:hover{background:none;}
</style>
