<template>
  <el-container>
    <div class="content ckdysjk">
      <div class="w-100 min-h-600" v-loading="loading.repository" v-if="loading.repository"></div>
      <repository-select v-if="subscription && !loading.repository" :alreadySelected="repositoryList" v-on:subscription="subscriptionChange"></repository-select>
      <div class="w-100" v-show="!subscription && !loading.repository">
        <el-card class="box-card ckhyzl">
          <div class="mod-title">
            <span class="f-l tit">查看订阅行业纵览</span>
            <el-button plain class="zk f-r hide" @click="baseFilter = !baseFilter">{{ baseFilter ? "收起" : "展开" }}</el-button>
          </div>
          <div class="clear"></div>
          <div v-if="baseFilter">
            <div class="box-shadow mar-t-20"></div>
            <el-form label-width="120px" class="mar-t-20 form-box demo-form-inline">
              <el-form-item label="选择订阅标题 *">
                <el-select placeholder="选择订阅行业纵览" v-model="where.id" class="f-l mar-r-10">
                  <el-option :label="row.name" :value="row.id" v-for="(row, index) in repositoryList" :key="index"></el-option>
                </el-select>
                <el-button plain @click="subscription = true" class="subscription bd-n" icon="iconfont icon-dingyue hover">重新订阅</el-button>
              </el-form-item>
              <el-form-item label="时间范围 *" class="w-50">
                <el-date-picker class="w-310" @change="dateSpanChange" :clearable="false" :picker-options="{disabledDate: dateRange}" v-model="otherWhere.dateSpan" :editable="false" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" align="right">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="排序方式" class="w-50">
                <el-select v-model="order" placeholder="请选择" class="w-200">
                  <el-option v-for="(item, index) in orderOptions" :key="index" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="信息等级" class="w-50">
                <el-radio-group v-model="where.msg_level">
                  <el-radio label="all">全部</el-radio>
                  <el-radio label="normal">精准信息</el-radio>
                  <el-radio label="garbage">噪音信息</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="情感属性" class="w-50">
                <el-radio-group v-model="where.emotion">
                  <el-radio label="all">全部</el-radio>
                  <el-radio label="negative">敏感</el-radio>
                  <el-radio label="positive">非敏感</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="相似合并" class="w-50">
                <el-radio-group v-model="where.similar">
                  <el-radio label="no">不合并</el-radio>
                  <el-radio label="yes">合并</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="语言" class="w-50">
                <el-radio-group class="radio_group" v-model="where.lang">
                  <el-radio label="all">全部</el-radio>
                  <el-radio label="zh">中文</el-radio>
                  <el-radio label="en">其他</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="定向信源" class="dxxy">
                <el-radio-group v-model="where.source_focus_type" class="radio_group">
                  <el-radio label="all">全部</el-radio>
                  <el-radio label="include">定向监测</el-radio>
                  <div :class="{'w-120': true, 'mar-r-20': true, 'pos-r': true, 'f-l': true, selected: sourceFocus.include.show}" v-show="where.source_focus_type === 'include'">
                    <el-input
                            class="w-120"
                            placeholder="请选择"
                            suffix-icon="el-icon-arrow-down"
                            :readonly="true"
                            v-model="sourceFocus.include.name"
                            @focus="sourceFocus.include.show = true"
                            @blur="sourceBlur('include')" />
                    <div class="dxxy-cont" v-if="sourceFocus.include.show">
                      <ul>
                        <li v-for="(item, index) in sourceIncludeList" :key="index" class="selected" @click="selectedSource(item, 'include')">
                          <span>{{ item.label }}</span>
                          <i class="iconfont icon-bianji cur-p" @click="editSourcePlan(item.value)"></i>
                        </li>
                      </ul>
                      <p class="add" @click="createSource('include')">
                        <i class="iconfont icon-zengjia f-l"></i>
                        <span>新增信源方案</span>
                      </p>
                    </div>
                  </div>
                  <el-radio label="exclude">定向排除</el-radio>
                  <div :class="{'w-120': true, 'mar-r-20': true, 'pos-r': true, 'f-l': true, selected: sourceFocus.exclude.show}" v-show="where.source_focus_type === 'exclude'">
                    <el-input
                            class="w-120"
                            placeholder="请选择"
                            suffix-icon="el-icon-arrow-down"
                            :readonly="true"
                            v-model="sourceFocus.exclude.name"
                            @focus="sourceFocus.exclude.show = true"
                            @blur="sourceBlur('exclude')" />
                    <div class="dxxy-cont" v-if="sourceFocus.exclude.show">
                      <ul>
                        <li v-for="(item, index) in sourceExcludeList" :key="index" class="selected" @click="selectedSource(item, 'exclude')">
                          <span>{{ item.label }}</span>
                          <i class="iconfont icon-bianji cur-p" @click="editSourcePlan(item.value)"></i>
                        </li>
                      </ul>
                      <p class="add" @click="createSource('exclude')">
                        <i class="iconfont icon-zengjia f-l"></i>
                        <span>新增信源方案</span>
                      </p>
                    </div>
                  </div>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="媒体类型">
                <div class="flex mtlx">
                  <el-checkbox :indeterminate="otherWhere.isIndeterminate" v-model="otherWhere.checkAll" @change="handleCheckAllChange()">全选<span style="color: #7c7e81">({{ countTotal }})</span></el-checkbox>
                  <el-checkbox-group v-model="where.media" @change="checkboxChange()" class="flex-1">
                    <el-checkbox v-for="(media, index) in medias" :label="media" :key="index">{{media}}<span style="color: #7c7e81">{{ statistics[media] }}</span></el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </el-form>
            <el-row>
              <div class="button-group">
                <el-button type="primary" round @click="mustLoad" v-loading="loading.important  || loading.data" :disabled="loading.important || loading.data">查询</el-button>
                <el-button round @click="cancelLoad">重置</el-button>
              </div>
            </el-row>
          </div>
        </el-card>
        <el-card class="box-card mar-t-20 zdxx" v-loading="loading.important">
          <div class="mod-title">
            <span>重点信息</span>
          </div>
          <div class="box-shadow mar-t-20"></div>
          <div class="mod-list mar-t-10">
            <ul>
              <li>
                <h3>
                  <span class="num-tit">序号</span>
                  <span class="mod-list-title1">标题</span>
                  <span class="tag">相似文章数</span>
                  <span class="mod-list-title2 t-c">媒体</span>
                </h3>
              </li>
              <li v-for="(row, index) in importantData" :key="index">
                <span class="num bg-555" v-if="index == 0">1</span>
                <span class="num bg-689" v-else-if="index == 1">2</span>
                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                <a class="mod-list-title1" @click="showDetail(row.rowkey)" :title="row.title">{{ row.title }}</a>
                <span class="tag">{{ row.similar_num }}</span>
                <dl class="mod-list-title2">
                  <dd v-for="(media, sub_index) in row.medias" :key="sub_index">
                    <var class="source_name">{{ media }}</var>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </el-card>
        <div v-loading="loading.data" class="mar-t-20">
          <el-card class="box-card xxlb">
            <div class="mod-title">信息列表</div>
            <div class="box-shadow"></div>
            <xxlb ref="list" :orderOptions="orderOptions" @exportData="exportData" @done="loadListDone()" />
          </el-card>
        </div>
      </div>
      <article-detail ref="detail" :materials="materials" />
    </div>
  </el-container>
</template>
<script>
import Select from './select.vue'
import articleDetail from '@components/common/article-detail.vue'
import xxlb from '@components/common/list.vue'
import { getList as getSourceList } from '@/api/directional-source'
import { shortcut, lists as repositoryList, hotpoint } from '@/api/repository'
import { lists as materialList, add_item } from '@/api/material'
import {
  dateRangeDisabled,
  sourceFocusId,
  globalFormatDate,
  queryString,
  getAuthToken,
  handleDownload
} from '@/utils/helpers.js'
import { orderOptions, medias } from '@/utils/constants'
import { mapGetters } from 'vuex'
const { _ } = window
export default {
  name: 'component-repository-list',
  components: {
    'repository-select': Select,
    articleDetail,
    xxlb
  },
  data() {
    return {
      orderOptions: orderOptions,
      materials: [],
      importantData: [],
      data: [],
      total: 0,
      countTotal: 0,
      currentPage: 1,
      currentPageSize: 20,
      order: 'intelligence',
      loading: { repository: false, data: false, important: false },
      subscription: false,
      repositoryList: [], // 知识库列表
      otherWhere: { dateSpan: '', isIndeterminate: false, checkAll: true },
      medias: medias,
      statistics: {},
      sourceFocus: {
        include: {
          show: false,
          name: ''
        },
        exclude: {
          show: false,
          name: ''
        }
      },
      where: {
        id: '',
        span: '',
        focus: { op: 'and', must: '', not: '' },
        emotion: 'all',
        lang: 'all',
        media: medias,
        similar: 'no',
        msg_level: 'normal',
        source_focus_type: 'all',
        source_focus_include_id: '', // 定向监测排除 ID,
        source_focus_exclude_id: ''
      },
      baseWhere: {},
      initWhere: {
        focus: { op: 'and', must: '', not: '' },
        emotion: 'all',
        lang: 'all',
        media: medias,
        similar: 'no',
        msg_level: 'normal',
        source_focus_type: 'all',
        source_focus_include_id: '', // 定向监测排除 ID,
        source_focus_exclude_id: ''
      },
      baseFilter: true,
      highFilter: false,
      joinMaterial: { visible: false, selectedId: '', loading: false },
      checkboxVisible: false,
      checkboxAll: false,
      checkedData: {},
      handleData: false,
      sourceIncludeList: [], // 定向信源 定向检测列表
      sourceExcludeList: [] // 定向信源 定向排除列表
    }
  },
  computed: {
    ...mapGetters(['orientationSourceList'])
  },
  watch: {
    'where.source_focus_type': function(val) {
      const { sourceExcludeList, sourceIncludeList } = this
      if (val === 'include') {
        if (!sourceIncludeList.length) {
          this.$message.warning('请先去定向监测管理中设置定向监测方案')
        }
      } else if (val === 'exclude') {
        if (!sourceExcludeList.length) {
          this.$message.warning('请先去定向监测管理中设置定向排除方案')
        }
      }
    }
  },
  mounted() {
    this.initDate()
    this.loadSourceData()
    this.loadRepositorys()
    this.loadMaterial()
    this.$store.dispatch('getSourceList')
  },
  methods: {
    loadSourceData() {
      getSourceList({ page: 1, size: 100 })
        .then(res => {
          const { state, error, data } = res.data
          if (state) {
            data.map(item => {
              const { name, id } = item
              if (item.type === 'exclude') {
                this.sourceExcludeList.push({
                  label: name,
                  value: id
                })
              } else {
                this.sourceIncludeList.push({
                  label: name,
                  value: id
                })
              }
            })
          }
        })
        .catch(err => {})
    },
    mustLoad() {
      var where = JSON.parse(JSON.stringify(this.where))
      if (!where.span) {
        this.$message.warning('请选择查阅知识库时间范围')
        return false
      }
      if (where.media.length < 1) {
        this.$message.warning('请选择媒体类型')
        return false
      }
      if (where.focus.must.split(',').length > 30 || where.focus.not.split(',').length > 30) {
        this.$message.warning('关键词组长度不能超过30组')
        return false
      }
      if (where.source_focus_type !== 'all' && sourceFocusId(where) === null) {
        this.$message.warning('请先选择定向方案，可在定向监测管理中设置新的定向方案')
        return false
      }
      this.baseWhere = where
      this.important(true)
      this.loadData()
    },
    exportData(total) {
      let params = JSON.parse(JSON.stringify(this.baseWhere))
      let args = {
        id: params.id,
        lang: params.lang,
        media: params.media.join(','),
        msg_level: params.msg_level,
        span: params.span,
        focus: JSON.stringify(params.focus),
        similar: params.similar,
        emotion: params.emotion,
        token: getAuthToken()
      }
      if (sourceFocusId(params)) args.source_focus = sourceFocusId(params)
      const query = decodeURIComponent(queryString(args, true))

      handleDownload(
        `${window.service.api}/repository/excel${query}`,
        'GET',
        `${window.$moment().format('YYYY-MM-DD')}.xlsx`,
        null,
        total,
        () => {
          this.$refs['list'].exportLoading = false
        }
      )
    },
    createSource(type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        return false
      }

      this.$router.push({ name: `orientation-${type}` })
    },
    sourceBlur(type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        return false
      }

      setTimeout(() => {
        this.sourceFocus[type].show = false
      }, 200)
    },
    editSourcePlan(id) {
      const plan = _.find(this.orientationSourceList, { id: id })
      if (plan !== undefined) {
        this.$router.push({ name: 'orientation-update', params: { data: JSON.stringify(plan) } })
      }
    },
    selectedSource(source, type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        return false
      }

      if (type === 'include') {
        this.where.source_focus_include_id = source.value
        this.sourceFocus.include.name = source.label
      } else {
        this.where.source_focus_exclude_id = source.value
        this.sourceFocus.exclude.name = source.label
      }
    },
    cancelLoad() {
      this.order = 'intelligence'
      Object.assign(this.where, this.initWhere)
      this.where.focus = { op: 'and', must: '', not: '' }
    },
    loadMaterial() {
      materialList()
        .then(res => {
          if (res.data.state) {
            var result = res.data.data
            this.materials = result
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(res => {
          this.$message.error('加载素材库失败，服务错误')
        })
    },
    loadRepositorys() {
      this.loading.repository = true
      repositoryList({ current: 1 })
        .then(res => {
          if (res.data.state) {
            this.loading.repository = false
            this.repositoryList = res.data.data
            this.subscription = this.repositoryList.length < 1 ? true : false
            if (this.repositoryList.length >= 1) {
              this.where.id = this.repositoryList[0].id
              let base = JSON.parse(JSON.stringify(this.where))
              this.baseWhere = base
              this.important()
              this.loadData()
            }
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(() => {
          this.$message.error('加载知识库列表失败，服务错误')
        })
    },
    important(refresh = false) {
      // 重点信息
      this.loading.important = true
      var params = JSON.parse(JSON.stringify(this.baseWhere))
      params.media = params.media.join(',')
      if (refresh) params.refresh = true
      hotpoint(params)
        .then(res => {
          this.loading.important = false
          if (res.data.state) {
            this.importantData = res.data.data
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(res => {
          this.loading.important = false
          this.$message.error('加载知识库列表失败，服务错误')
        })
    },
    loadData() {
      var params = JSON.parse(JSON.stringify(this.baseWhere))
      params.media = params.media.join(',')
      params.size = this.currentPageSize
      params.page = this.currentPage
      params.order = this.order
      params.source_focus = sourceFocusId(params)
      delete params.source_focus_type
      delete params.source_focus_exclude_id
      delete params.source_focus_include_id
      this.loading.data = true
      this.$refs.list.loadData(
        `${window.service.api}/repository/browse`,
        params,
        false,
        'repository'
      )
    },
    loadListDone() {
      this.loading.data = false
    },
    cancelSelected() {
      var _this = this
      this.checkboxAll = false
      this.checkboxVisible = false
      var selected = {}
      for (let key in this.checkedData) {
        selected[key] = false
      }
      this.checkedData = selected
      _(this.data).forEach((item, i) => {
        _this.data[i].checked = false
      })
    },
    showDetail(rowkey) {
      var keywords = []
      if (_.size(this.baseWhere.focus.must) >= 1) {
        let temp = this.baseWhere.focus.must.split(',')
        _(temp).forEach(v => {
          keywords.push(temp[v])
        })
      }
      this.$refs.detail.showDetail(rowkey, 'repository-list', keywords)
    },
    initDate() {
      var date = new Date()
      let begin = globalFormatDate(date, true)
      let now = globalFormatDate(date)
      this.otherWhere.dateSpan = [begin, now]
      this.where.span = begin + '~' + now
    },
    dateRange(date) {
      return dateRangeDisabled(date)
    },
    dateSpanChange(date) {
      if (date) {
        this.where.span = date[0] + '~' + date[1]
      } else {
        this.where.span = ''
      }
    },
    handleCheckAllChange() {
      this.where.media = this.otherWhere.checkAll ? this.medias : []
      this.otherWhere.isIndeterminate = false
    },
    checkboxChange() {
      let checkedCount = this.where.media.length
      this.otherWhere.checkAll = checkedCount === this.medias.length
      this.otherWhere.isIndeterminate = checkedCount > 0 && checkedCount < this.medias.length
    },
    handleSizeChange(val) {
      this.currentPageSize = val
      this.currentPage = 1
      this.loadData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.loadData()
    },
    orderChange() {
      this.currentPage = 1
      this.loadData()
    },
    subscriptionChange(state) {
      if (!state) {
        // 重新订阅后加载
        this.loadRepositorys()
        // 重新初始化
        this.where.focus = { op: 'and', must: '', not: '' }
        ;(this.where.emotion = 'all'), (this.where.lang = 'all')
        this.where.media = medias
        this.where.similar = 'no'
      }
      this.subscription = state
    }
  }
}
</script>
<style scoped>
.ckdysjk >>> .el-card__body {
  padding: 20px 40px 30px;
}
.ckdysjk .mod-title {
  margin-left: 0;
}
.ckdysjk .form-box {
  background: none;
  padding: 0;
}
.ckdysjk .form-box >>> .el-form-item--small.el-form-item {
  margin-bottom: 20px;
}
.ckdysjk .zk:hover,
.ckdysjk .zk.checked {
  color: #fff;
}
.w-276 {
  width: 276px;
}
.ckhyzl .mod-title {
  border: none;
  padding: 0;
}
.ckhyzl .mod-title .tit {
  padding-left: 15px;
  margin-top: 10px;
}
/* 重点信息 */
.zdxx .mod-list-title1 {
  width: 44%;
}
.zdxx .mod-list-title2 {
  width: 40%;
}
.zdxx .mod-list-title2 dd {
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 33%;
  position: relative;
  text-align: center
}
.zdxx .mod-list-title2 dd:not(:last-child)::after {
  content: ' | ';
  position: absolute;
  right:0;
  top: 0;
}
.ckdysjk .subscription:hover {
  background: none;
  color: #414141;
}
/* 2.0.6迭代 */
.ckdysjk .demo-form-inline .w-50 {
  width: 50%;
  display: inline-block;
}
/* 信息筛选 */
.ckdysjk .dxxy .selected >>> .el-icon-arrow-down {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.dxxy >>> .el-radio {
  float: left;
  margin-top: 5px;
}
.dxxy-cont {
  position: absolute;
  left: 0;
  top: 35px;
  z-index: 10;
  width: 180px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  padding: 6px 0;
}
.dxxy-cont ul {
  max-height: 145px;
  overflow: auto;
}
.dxxy-cont li,
.dxxy-cont .add {
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  display: flex;
}
.dxxy-cont li span,
.dxxy-cont .add span {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  cursor: pointer;
}
.dxxy-cont li .icon-bianji {
  display: none;
}
.dxxy-cont li:hover {
  background: rgba(62, 71, 255, 0.1);
}
.dxxy-cont li:hover span {
  color: #3e47ff;
}
.dxxy-cont li:hover .icon-bianji {
  display: block;
}
.dxxy-cont li i {
  font-size: 14px;
  line-height: 35px;
}
.dxxy-cont .add {
  border-top: 1px solid #f2f2f2;
}
</style>
